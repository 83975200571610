



























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class JoinButton extends Vue {
  @Prop({}) action?: 'follow' | 'unfollow'
  @Prop({}) loading?: boolean
  @Prop({ default: false }) disabled?: boolean
  @Prop({ default: false, type: Boolean }) plain!: boolean
}
